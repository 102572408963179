<template>
    <div class="grid dashboard">
    <CapitalSocial :matricula="currentUser?.matricula"/>
    </div>
</template>

<script lang="js">
    
    import CapitalSocial from '../../components/widgets/CapitalSocial.vue'

    export  default {
        computed: {
			currentUser() {

                return this.$store.state.auth.user;
			
            }
		},

        components: { CapitalSocial }
    }

</script>