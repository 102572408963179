<template>

    <div class="col-12 md:col-6 lg:col-3">
        <div class="card overview-box flex flex-column pt-2">
            <div class="flex align-items-center muted-text">
                <i class="pi pi-money-bill"></i>
                <h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">Capital social</h6>
            </div>
            <div class="flex justify-content-between mt-3 flex-wrap">
                <div class="flex flex-column" style="width: 100px;">
                    <span class="mb-1 fs-xlarge">{{valorCapitalSocial}}</span>
                    <span class="overview-status p-1 teal-bgcolor fs-small">{{dataUltimaIncorporacao}} Incorporação</span>
                </div>
                <div class="flex align-items-end">
                    <Chart ref="overviewChartData1" type="line" :data="overviewChartData1" :options="overviewChartOptions" responsive="true" :height="60" :width="160"></Chart>
                </div>
            </div>
        </div>
    </div>

</template>

<script lang="js">
    import CapitalSocialService from '../../service/CapitalSocialService'
    export default {
        name: 'CapitalSocial', 
        props: {
			matricula: {
				type: Number,
				required: true
			},
		},
        data() {
           return {
            valorCapitalSocial: null,
            dataUltimaIncorporacao: null,
            overviewChartOptions: {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                responsive: true,
                scales: {
                    y: {
                        display: false
                    },
                    x: {
                        display: false
                    }
                },
                tooltips: {
                    enabled: false
                },
                elements: {
                    point: {
                        radius: 0
                    }
                },
            },
            overviewChartData1: {
                labels: ["January", "February", "March", "April", "May", "June", "July", "August", "September"],
                datasets: [
                    {
                        data: [30, 24, 32, 24, 18, 27, 20, 36, 50],
                        borderColor: [
                            "#4DD0E1",
                        ],
                        backgroundColor: [
                            "rgba(77, 208, 225, 0.8)",
                        ],
                        borderWidth: 2,
                        fill: true,
                        tension: 0.4
                    }
                ]
            }
           }
        },
        created() {
			this.getCapitalSocial();
		},
        methods: {
            getCapitalSocial() {                       
                CapitalSocialService.getCapitalSocial(this.$props.matricula).then((response) => {
                    this.valorCapitalSocial = this.formatCurrency(response.data.valor);
                    this.dataUltimaIncorporacao = this.formatDate(response.data.dataUltimaIncorporacao);
                })   
            
            },
            formatDate(data) {
                var dataPadrao = new Date(data);
                var d = dataPadrao.toLocaleDateString("pt-BR");
                return d ;
			},
            
            formatCurrency(value) {
				return value ? value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) : 'R$ 0,00';
			},
            refreshChart() {
                this.setOverviewColors();
            },
        
            setOverviewColors() {
                const { tealBorderColor, tealBgColor } = this.getOverviewColors();
                this.overviewChartData1.datasets[0].borderColor[0] = tealBorderColor;
                this.overviewChartData1.datasets[0].backgroundColor[0] = tealBgColor;
                this.$refs.overviewChartData1.reinit();
            },
            getOverviewColors() {
                const isLight = this.$appState.layoutMode === "light";
                return {
                    tealBorderColor: isLight ? "#009688" : "#26A69A",
                    tealBgColor: isLight ? "#80CBC4" : "#B2DFDB",
                    whiteBorderColor: isLight ? "#ffffff" : "#ffffff",
                    whiteBgColor: isLight ? "rgba(255,255,255,.35)" : "rgba(255,255,255,.35)",
                };
            },
        },
        computed: {
            isRTL() {
                return this.$appState.RTL;
            },
            currentUser() {
                return this.$store.state.auth.user;
            }
        }

    }
    
</script>