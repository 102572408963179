import axios from "axios";
import PortalCooperadoUtils from '../utilities/PortalCooperadoUtils';

const CAPITAL_SOCIAL_API_BASE_URL = PortalCooperadoUtils.apiCooperado;
const RESOURCE_V1 = `/v1/capital-social`;

class CapitalSocialService {

    getCapitalSocial(matricula) {
        return axios.get(CAPITAL_SOCIAL_API_BASE_URL + RESOURCE_V1 + "/" + matricula);
    }
}

export default new CapitalSocialService();